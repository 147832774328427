import * as _ from 'lodash';
import React from 'react';
import ReactDOM from 'react-dom';

import StoriesAndVisualizationsWrapper from './components/StoriesAndVisualizationsWrapper';

ReactDOM.render(
  <StoriesAndVisualizationsWrapper />,
  document.querySelector('#stories-and-visualizations-main')
);
