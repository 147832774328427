import I18n from 'common/i18n';
import { cloneDeep, isEmpty, map, reject, some } from 'lodash';

export interface ColorPalette {
  name: string;
  hexCodes: string[];
  id: string;
}

const scope = 'screens.admin.stories_and_visualizations.color_tab';

export const VALID_HEX_CODE_PATTERN = /^#([A-F0-9]{3}|[A-F0-9]{6})$/i;

export const getArrayFromSerializedTextArea = (value: string) => {
  let array = [] as string[];

  const hexCodes = reject(value.split(/[,;\s]+/g), (item) => isEmpty(item));

  try {
    array = hexCodes;
  } catch (error) {
    /* ignorable */
  }

  return array;
};

export const hasValidColorPaletteHexCodes = (colorPalettes: ColorPalette[]) => {
  for (let i = 0; i < colorPalettes.length; i++) {
    const palette = colorPalettes[i];

    for (let j = 0; j < palette.hexCodes.length; j++) {
      const hexCode = palette.hexCodes[j];
      if (!VALID_HEX_CODE_PATTERN.test(hexCode)) {
        return false;
      }
    }
  }

  return true;
};

export const hasValidHexCodes = (hexCodes: string[]) => {
  for (let i = 0; i < hexCodes.length; i++) {
    const hexCode = hexCodes[i];
    if (!VALID_HEX_CODE_PATTERN.test(hexCode)) {
      return false;
    }
  }

  return true;
};

// Color Palette Helpers
export const getColorPalette = (index: number, colorPalettes: ColorPalette[]) => {
  return index >= 0 && index < colorPalettes.length ? colorPalettes[index] : null;
};

export const getColorPaletteName = (colorPalettes: ColorPalette[]) => {
  const defaultName = I18n.t('color_palettes.new_palette_label', { scope });
  let i = 1;
  let name = `${defaultName} ${i}`;

  // Increment color palette number until we find a unique one
  while (some(colorPalettes, (palette) => palette.name === name)) {
    name = `${defaultName} ${i++}`;
  }

  return name;
};

export const getNextColorPaletteIndex = (currentIndex: number, colorPalettes: ColorPalette[]) => {
  if (currentIndex < colorPalettes.length - 1) {
    return currentIndex;
  }

  const index = currentIndex - 1;
  if (index >= 0 && index < colorPalettes.length) {
    return index;
  }

  // If there are no color palettes, return -1
  return -1;
};

export const hasUniqueColorPaletteNames = (name: string, colorPalettes: ColorPalette[]) => {
  const paletteNames = map(colorPalettes, (palette) => palette.name);
  return !paletteNames.includes(name);
};

export const updateColorPaletteProperty = (colorPalettes: ColorPalette[], index: number, property: string, value: any) => {
  const updatedColorPalettes = cloneDeep(colorPalettes);
  switch (property) {
    case 'hexCodes':
      updatedColorPalettes[index].hexCodes = value;
      break;
    case 'name':
      updatedColorPalettes[index].name = value;
      break;
  }

  return updatedColorPalettes;
};
