import React, { FunctionComponent } from 'react';
import I18n from 'common/i18n';
import { VALID_HEX_CODE_PATTERN } from '../colorSettingsHelpers';
import './index.scss';

interface Props {
  colors: string[];
}

const ColorPickerPreview: FunctionComponent<Props> = ({
  colors
}) => {
  const scope = 'screens.admin.stories_and_visualizations.color_tab';

  return (
    <div id="color-picker-preview-container" className="colors-preview-container" role="presentation" aria-hidden="true">
      <label className="color-preview-label">{I18n.t('color_picker.preview', { scope })}</label>
      <div className="color-picker-preview-colors">
        {colors.map((color: string, index: number) => {
          // If the user has entered an invalid hex code, we don't want to show any color in the preview, so set it to white
          if (!VALID_HEX_CODE_PATTERN.test(color)) {
            color = '#FFFFFF';
          }
          return (<span
              key={index.toString()}
              className="color-picker-preview-color"
              style={{ backgroundColor: color }}><div/>
            </span>);
          })}
      </div>
    </div>);
};

export default ColorPickerPreview;
