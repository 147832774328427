import React, { FunctionComponent } from 'react';
import { ForgeCard } from '@tylertech/forge-react';
import { range } from 'lodash';
import I18n from 'common/i18n';
import { VALID_HEX_CODE_PATTERN } from '../colorSettingsHelpers';
import './index.scss';

interface Props {
  colors: string[];
}

const ColorPalettePreview: FunctionComponent<Props> = ({
  colors
}) => {
  const height = range(95, (95 - colors.length * 5) , -5);
  const scope = 'screens.admin.stories_and_visualizations.color_tab';

  return (
    <ForgeCard outlined id="color-palette-preview-container" role="presentation" aria-hidden="true">
      <label className="color-preview-label">{I18n.t('color_palettes.preview', { scope })}</label>
      <div className="color-palette-color-swatches">
        {colors.map((color: string, index: number) => {
          const barHeight = height[index];
          // If the user has entered an invalid hex code, we don't want to show any color in the preview, so set it to white
          if (!VALID_HEX_CODE_PATTERN.test(color)) {
            color = '#FFFFFF';
          }
          return (<span
            key={index.toString()}
            className="color-palette-preview-color"
            style={{ backgroundColor: color, height: barHeight }}><div/></span>
          );
        })}
      </div>
    </ForgeCard>);
};

export default ColorPalettePreview;
