import React, { FunctionComponent, useState, useEffect, useMemo } from 'react';
import {
  ForgeButton,
  ForgeIcon,
  ForgeList,
  ForgeListItem,
  ForgeTextField,
  ForgeToast
} from '@tylertech/forge-react';
import { IListItemSelectEventData } from '@tylertech/forge';
import I18n from 'common/i18n';
import {
  cloneDeep,
  isEmpty,
  get
} from 'lodash';
import { fetchWithDefaultHeaders } from 'common/http';
import ColorPickerPreview from './ColorPickerPreview';
import ColorPalettePreview from './ColorPalettePreview';
import {
  getArrayFromSerializedTextArea,
  hasValidColorPaletteHexCodes,
  hasValidHexCodes,
  getColorPalette,
  getColorPaletteName,
  getNextColorPaletteIndex,
  hasUniqueColorPaletteNames,
  updateColorPaletteProperty,
  ColorPalette
} from './colorSettingsHelpers';

interface Props {}

const colorsConfig = get(window, 'socrata.siteChrome.colors');

const ColorSettingsPanel: FunctionComponent<Props> = () => {
  const hasPalette = colorsConfig.colorPalettes.length > 0;
  // This tracks the toast, whether it is showing and its message
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [hasInvalidHexCodes, setHasInvalidHexCodes] = useState(false);
  const [pickerHexArray, setPickerHexArray] = useState(colorsConfig.colorPicker.hexCodes);
  const [pickerHexString, setPickerHexString] = useState(colorsConfig.colorPicker.hexCodes.join(' '));

  const [colorPalettes, setColorPalettes] = useState(
    hasPalette ? colorsConfig.colorPalettes : []
  );
  const [hasInvalidPaletteHexCodes, setHasInvalidPaletteHexCodes] = useState(false);
  const [palettePlaceholderName, setPalettePlaceholderName] = useState('');
  const [paletteHexCodes, setPaletteHexCodes] = useState(hasPalette ? colorPalettes[0]?.hexCodes : []);
  const [paletteHexString, setPaletteHexString] = useState(hasPalette ? colorPalettes[0]?.hexCodes.join(' ') : '');
  const [selectedPaletteName, setSelectedPaletteName] = useState(
    hasPalette ? colorPalettes[0]?.name : ''
  );
  // This tracks the index of the selected color palette, in the case there are no palettes it is -1
  const [selectedColorPaletteIndex, setSelectedColorPaletteIndex] = useState(
    hasPalette ? 0 : -1
  );

  const [hasInvalidPaletteName, setHasInvalidPaletteName] = useState(false);

  const scope = 'screens.admin.stories_and_visualizations.color_tab';

  // Toast
  const toastOptions = {
    placement: 'top',
    message: toastMessage,
    id: 'stories-visualizations-toast'
  };

  const clearToast = () => {
    setShowToast(false);
  };


  useEffect(() => {
    async function updateConfigProperty() {
      if (hasValidColorPaletteHexCodes(colorPalettes) && !hasInvalidHexCodes) {
        const url = '/admin/stories_visualizations/site_config/site_chrome';
        const body = {
          properties: {
            colors: {
              color_picker: {
                hex_codes: JSON.stringify(pickerHexArray)
              },
              color_palettes: {
                palettes: JSON.stringify(colorPalettes)
              }
            }
          }
        };

        const options = {
          body: JSON.stringify(body),
          method: 'PUT'
        };

        handleRequestWithToast(url, options);
      }
    }

    async function handleRequestWithToast(url: string, options: object) {
      let success = true;
      try {
        const response = await fetchWithDefaultHeaders(url, options);
        if (!response.ok) {
          success = false;
        }
      } catch (err) {
        success = false;
      }

      setToastMessage(
        success
          ? I18n.t('screens.admin.stories_and_visualizations.filter_tab.toast_success')
          : I18n.t('screens.admin.stories_and_visualizations.filter_tab.toast_error')
      );
      setShowToast(true);
    }

    updateConfigProperty();
  }, [pickerHexArray, colorPalettes]);

  useEffect(() => {
    const hexArray = getArrayFromSerializedTextArea(pickerHexString);
    setPickerHexArray(hexArray);
    const hasValidPickerHexCodes = hasValidHexCodes(hexArray);
    setHasInvalidHexCodes(!hasValidPickerHexCodes);
  }, [pickerHexString]);

  useEffect(() => {
    if (selectedColorPaletteIndex >= 0 && selectedColorPaletteIndex < colorPalettes.length) {
      const hexCodes = getArrayFromSerializedTextArea(paletteHexString);
      setPaletteHexCodes(hexCodes);
      const hasValidPaletteHexCodes = hasValidHexCodes(hexCodes);
      setHasInvalidPaletteHexCodes(!hasValidPaletteHexCodes);

      if (hasValidHexCodes(hexCodes)) {
        const updatedColorPalettes = updateColorPaletteProperty(colorPalettes, selectedColorPaletteIndex, 'hexCodes', hexCodes);
        setColorPalettes(updatedColorPalettes);
      }
    }
  }, [paletteHexString]);


  // Color Picker
  const onInputPickerHexCodes = (hexString: string) => {
    setPickerHexString(hexString);
  };

  // Color Palettes
  const onClickAddPalette = () => {
    const name = getColorPaletteName(colorPalettes);
    const updatedColorPalettes = cloneDeep(colorPalettes);
    updatedColorPalettes.push({ hexCodes: [], name });
    setColorPalettes(updatedColorPalettes);
    setSelectedColorPaletteIndex(updatedColorPalettes.length - 1);
    renderPaletteByIndex(updatedColorPalettes.length - 1);
  };

  const onClickDeletePalette = () => {
    if (selectedColorPaletteIndex >= 0 && selectedColorPaletteIndex < colorPalettes.length) {
      const updatedColorPalettes = cloneDeep(colorPalettes);
      updatedColorPalettes.splice(selectedColorPaletteIndex, 1);
      setColorPalettes(updatedColorPalettes);
      renderPaletteByIndex(getNextColorPaletteIndex(selectedColorPaletteIndex, colorPalettes));
    }
  };

  const onInputPaletteHexCodes = (hexCodesText: string) => {
    const palette = getColorPalette(selectedColorPaletteIndex, colorPalettes);
    if (palette) {
      setPaletteHexString(hexCodesText);
    }
  };

  const onInputPaletteName = (newName: string) => {
    const palette = getColorPalette(selectedColorPaletteIndex, colorPalettes);
    const hasValidColorPaletteName = hasUniqueColorPaletteNames(newName, colorPalettes) && !isEmpty(newName);

    setHasInvalidPaletteName(!hasValidColorPaletteName);
    if (palette) {
      if (isEmpty(newName)) {
        setPalettePlaceholderName(getColorPaletteName(colorPalettes));
      }
      setSelectedPaletteName(newName);
      const updatedColorPalettes = updateColorPaletteProperty(colorPalettes, selectedColorPaletteIndex, 'name', newName);
      setColorPalettes(updatedColorPalettes);
    }
  };

  const renderPaletteByIndex = (index: number) => {
    if (index < 0) {
      // In the case we've deleted the last palette, we want to reset the palette state
      setPaletteHexCodes([]);
      setPaletteHexString('');
      setSelectedPaletteName('');
      setHasInvalidPaletteHexCodes(false);
    } else {
      setSelectedColorPaletteIndex(index);
      setPaletteHexCodes(colorPalettes[index].hexCodes);
      setPaletteHexString(colorPalettes[index].hexCodes.join(' '));
      setSelectedPaletteName(colorPalettes[index].name);
      setHasInvalidPaletteHexCodes(!hasValidHexCodes(colorPalettes[index].hexCodes));
    }
  };

  const renderPaletteListItem = (palette: ColorPalette, index: number) => {
    const isSelected = colorPalettes.indexOf(palette) === selectedColorPaletteIndex;

    const handleSelect = (evt: CustomEvent<IListItemSelectEventData>) => {
      if (evt.detail.value === undefined) {
        return;
      } else {
        renderPaletteByIndex(parseInt(evt.detail.value));
      }
    };
    return (
      <ForgeListItem key={index} value={index} selected={isSelected} on-forge-list-item-select={handleSelect}>
        <span className="forge-typography--body1" aria-hidden="true">
          {palette.name}
        </span>
      </ForgeListItem>
    );
  };

  return (
    <div>
      <ForgeToast options={toastOptions} open={showToast} onDismiss={clearToast}></ForgeToast>
      <div id="ViewSwitcher" className="color-settings-panel-container">
        <div className="color-picker-container">
          <div className="forge-typography--subtitle1-secondary">
            {I18n.t('color_picker.title', { scope })}
          </div>
          <p className="forge-typography--subtitle1">{I18n.t('color_picker.description_1', { scope })}</p>
          <p className="forge-typography--subtitle1 color-picker-description-2">
            {I18n.t('color_picker.description_2', { scope })}
          </p>
          <div className="color-picker-hex-codes-container">
            <div className="color-picker-hex-code-input-container">
              <ForgeTextField
                invalid={hasInvalidHexCodes}
                floatLabelType="always"
                className="color-picker-hex-code-input"
              >
                <textarea
                  id="color-picker-hex-codes"
                  rows={4}
                  value={pickerHexString}
                  onChange={(event) => onInputPickerHexCodes(event.target.value)}
                />
                <label htmlFor="color-picker-hex-codes" slot="label">
                  {I18n.t('color_picker.hex_codes', { scope })}
                </label>
              </ForgeTextField>
              <p className="forge-typography--subtitle2-secondary hex-code-input-description">
                {I18n.t('hex_code_helper_text', { scope })}
              </p>
            </div>
            <div className="color-picker-preview-container">
              <ColorPickerPreview colors={pickerHexArray} />
            </div>
          </div>
        </div>
        <div className="color-palettes-container">
          <div className="forge-typography--subtitle1-secondary">
            {I18n.t('color_palettes.title', { scope })}
          </div>
          <p className="forge-typography--subtitle1">{I18n.t('color_palettes.description_1', { scope })}</p>
          <p className="forge-typography--subtitle1">{I18n.t('color_palettes.description_2', { scope })}</p>
          <ForgeButton className="add-palette-button" type="raised">
            <button type="button" onClick={onClickAddPalette}>
              <ForgeIcon name="add"></ForgeIcon>
              <span>{I18n.t('color_palettes.add_new_palette', { scope })}</span>
            </button>
          </ForgeButton>
          <div className="color-palette-input-container">
            <div className="color-palette-list-container">
              <ForgeList>{(colorPalettes.length > 0) ? colorPalettes.map(renderPaletteListItem) : null}</ForgeList>
              <div className="delete-palette-button">
                <ForgeButton type="outline">
                  <button type="button" disabled={colorPalettes.length === 0} onClick={onClickDeletePalette}>
                    <ForgeIcon name="delete"></ForgeIcon>
                    <span>{I18n.t('color_palettes.delete_palette', { scope })}</span>
                  </button>
                </ForgeButton>
              </div>
            </div>
            <div className="color-palette-edit-container">
              <ForgeTextField
                className="palette-name-input"
                invalid={hasInvalidPaletteName}
                floatLabelType="always"
              >
                <input
                  id="palette-name"
                  placeholder={palettePlaceholderName}
                  value={selectedPaletteName}
                  onChange={(event) => onInputPaletteName(event.target.value)}
                />
                <label htmlFor="palette-name" slot="label">
                  {I18n.t('color_palettes.name_input_label', { scope })}
                </label>
              </ForgeTextField>
              <ForgeTextField
                invalid={hasInvalidPaletteHexCodes}
                className="palette-hex-code-input"
                floatLabelType="always"
              >
                <textarea
                  id="palette-hex-codes"
                  rows={3}
                  value={paletteHexString}
                  onChange={(event) => onInputPaletteHexCodes(event.target.value)}
                />
                <label htmlFor="palette-hex-codes" slot="label">
                  {I18n.t('color_palettes.hex_codes', { scope })}
                </label>
              </ForgeTextField>
              <p className="forge-typography--subtitle2-secondary hex-code-input-description">
                {I18n.t('hex_code_helper_text', { scope })}
              </p>
            </div>
          </div>
          <ColorPalettePreview colors={paletteHexCodes} />
        </div>
      </div>
    </div>
  );
};

export default ColorSettingsPanel;
