import React, { FunctionComponent, useState } from 'react';
import { ForgeTabBar, ForgeTab, ForgeViewSwitcher, ForgeView } from '@tylertech/forge-react';
import FilterSettingsPanel from './FilterSettingsPanel';
import ColorSettingsPanel from './ColorSettingsPanel';
import I18n from 'common/i18n';
import FeatureFlags from 'common/feature_flags';

interface Props {
}

const StoriesAndVisualizationsWrapper: FunctionComponent<Props> = () => {
  const [currentTab, setCurrentTab] = useState(0);
  const scope = 'screens.admin.stories_and_visualizations';

  const updateViewIndex = (event: any) => {
    setCurrentTab(event.detail.index);
  };

  return (
    <div id='ViewSwitcher' className='stories-and-visualizations-container'>
      <h5 className='forge-typography--headline5'>{I18n.t('subtitle',{scope})}</h5>
      <div role="tablist">
        <ForgeTabBar underline layoutMode={'clustered'} activeTab={currentTab} on-forge-tab-bar-activate={updateViewIndex}>
          <ForgeTab>{I18n.t('tabs.color',{scope})}</ForgeTab>
          <ForgeTab>{I18n.t('tabs.filter',{scope})}</ForgeTab>
        </ForgeTabBar>
        <ForgeViewSwitcher index={currentTab}>
          <ForgeView role="tabpanel">
            <ColorSettingsPanel/>
          </ForgeView>
          <ForgeView role="tabpanel">
            <FilterSettingsPanel/>
          </ForgeView>
        </ForgeViewSwitcher>
      </div>
    </div>
  );
};

export default StoriesAndVisualizationsWrapper;
