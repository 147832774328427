import React, { FunctionComponent, useState, useEffect } from 'react';
import { ForgeSwitch, ForgeOption, ForgeSelect, ForgeToast } from '@tylertech/forge-react';
import I18n from 'common/i18n';
import { get, map, range, isUndefined} from 'lodash';
import { fetchWithDefaultHeaders } from 'common/http';
import FeatureFlags from 'common/feature_flags';

interface Props {

}
const config = get(window, 'socrata.fiscalYearConfig');
const fyStartMonth = config ? config.fy_month : 0;

const FilterSettingsPanel: FunctionComponent<Props> = () => {
  const [fyFiltersEnabled, setFYfiltersEnabled] = useState(config ? !!config.fy_filters_enabled : false);
  const [selectedMonth, setMonth] = useState(config ? fyStartMonth : 0);
  // This tracks the toast, whether it is showing and its message
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const scope = 'screens.admin.stories_and_visualizations';

  const updateEnableFiscalYear = (event: CustomEvent) => {
    setFYfiltersEnabled(!!event.detail);

    const propertyValue = {
      'fy_filters_enabled': !!event.detail
    };
    updateConfigProperty( propertyValue );
  };

  const updateFYMonth = (event: any) => {
    setMonth(event.target.value);
    const propertyValue = {
      'fy_month': event.target.value,
    };
    updateConfigProperty( propertyValue );
  };

  async function updateConfigProperty( value: object ) {
    const url = '/admin/stories_visualizations/site_config/fiscal_year';
    const body = {
      'properties': value
    };

    const options = {
      body: JSON.stringify(body),
      method: 'PUT'
    };

    handleRequestWithToast(url, options);
  }


  const getMonthOptions = () => {
    const months = range(0, 12);
    return map(months, (month) => ({
      title: I18n.t(`date.month_${month}`),
      value: month
    }));
  };

  const renderMonthOptions = getMonthOptions().map((month, index) => {
    return (
      <ForgeOption value={month.value} key={index}>
        {month.title}
      </ForgeOption>
    );
  });

  const toastOptions = {
    placement: 'top',
    message: toastMessage,
    id: 'stories-visualizations-toast'
  };

  const clearToast = () => {
    setShowToast(false);
  };

  async function handleRequestWithToast(url: string, options: object) {
    let success = true;
    try {
      const response = await fetchWithDefaultHeaders(url, options);
      if (!response.ok) {
        success = false;
      }

    } catch (err) {
      success = false;
    }

    setToastMessage(success ? I18n.t('filter_tab.toast_success', { scope })
                            : I18n.t('filter_tab.toast_error', { scope }));
    setShowToast(true);
  }

  return (
    <div>
      <ForgeToast options={toastOptions} open={showToast} onDismiss={clearToast}></ForgeToast>
      <div id="ViewSwitcher" className="filter-settings-panel-container">
        <div className="forge-typography--subtitle1-secondary">
          {I18n.t('filter_tab.fy', { scope })}
        </div>
        <p className="forge-typography--body1">{I18n.t('filter_tab.fy_description', { scope })}</p>
        <div className='fiscal-year-dropdown-container'>
          <ForgeSelect
            className='fy-month'
            selectedIndex={selectedMonth}
            onChange={updateFYMonth}
            label={I18n.t('filter_tab.fy_start_month', { scope })}
            floatLabelType={'always'}
          >
            {renderMonthOptions}
          </ForgeSelect>
        </div>
        <div className='fiscal-year-switch'>
          <ForgeSwitch
            className='enable-fiscal-year'
            selected={fyFiltersEnabled}
            on-forge-switch-select={(event: CustomEvent) => updateEnableFiscalYear(event)}
          >
            <span>{I18n.t('filter_tab.fy_switch_label', { scope })}</span>
          </ForgeSwitch>
        </div>
      </div>
    </div>
  );
};

export default FilterSettingsPanel;
